<template>
  <b-overlay
    variant="transparent"
    :show="isProcessing"
  >
    <b-row>
      <b-col
        lg="12"
        md="12"
      >
        <b-form @submit.prevent="onSubmit">
          <b-card>
            <!-- logo -->
            <b-row
              class="mb-2 pl-0"
            >
              <b-col
                lg="12"
              >
                <avatar-upload
                  entity="user"
                  :avatar-url="userData.avatar"
                  :is-full="true"
                  :config="avatarUploadConfig"
                />
              </b-col>
            </b-row>
            <b-row>
              <b-col
                lg="12"
                md="12"
                class="d-block"
              >
                <b-row>
                  <b-col
                    lg="6"
                    md="12"
                  >
                    <b-form-group
                      label="Имя"
                    >
                      <b-input-group class="input-group-merge">
                        <b-form-input
                          v-model="model.firstname"
                          :state="errors.firstname ? false : null"
                        />
                      </b-input-group>
                      <small
                        v-if="errors.firstname"
                        class="text-danger"
                      >
                        {{ errors.firstname }}
                      </small>
                    </b-form-group>
                  </b-col>
                  <b-col
                    lg="6"
                    md="12"
                  >
                    <b-form-group
                      label="Фамилия"
                    >
                      <b-input-group class="input-group-merge">
                        <b-form-input
                          v-model="model.surname"
                          :state="errors.surname ? false : null"
                        />
                      </b-input-group>
                      <small
                        v-if="errors.surname"
                        class="text-danger"
                      >
                        {{ errors.surname }}
                      </small>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col
                    lg="6"
                    md="12"
                  >
                    <b-form-group
                      label="Email"
                    >
                      <b-input-group class="input-group-merge">
                        <b-form-input
                          v-model="model.email"
                          type="email"
                          :state="errors.email ? false : null"
                          placeholder="info@my-company.com"
                        />
                      </b-input-group>
                      <small
                        v-if="errors.email"
                        class="text-danger"
                      >
                        {{ errors.email }}
                      </small>
                    </b-form-group>
                  </b-col>
                  <b-col
                    lg="6"
                    md="12"
                  >
                    <PhoneInput
                      ref="phoneInput"
                      v-model="model.phone"
                      :label="t('auth.field.phone')"
                    />
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
            <b-alert
              v-if="!isEmailConfirmed"
              variant="warning"
              class="mt-1"
              show
            >
              <h4 class="alert-heading">
                {{ t('settings.profile.emailNotConfirmedMessage') }}
              </h4>
              <div class="alert-body">
                <b-link
                  class="alert-link"
                  @click.prevent="onResendVerification"
                >
                  {{ t('settings.profile.resendVerification') }}
                  <b-spinner
                    v-if="resendingVerification"
                    small
                  />
                </b-link>
              </div>
            </b-alert>
            <b-row class="mt-2 ml-lg-25">
              <b-col
                class="p-0"
              >
                <b-button
                  type="submit"
                  variant="primary"
                  class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                  :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                >
                  {{ t('settings.save') }}
                </b-button>
              </b-col>
            </b-row>
          </b-card>
        </b-form>
      </b-col>
    </b-row>
  </b-overlay>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BFormGroup,
  BFormInput,
  BForm,
  BButton,
  BInputGroup,
  BOverlay,
  BLink,
  BAlert,
  BSpinner,
} from 'bootstrap-vue';
import Ripple from 'vue-ripple-directive';
import { computed, ref } from '@vue/composition-api';

import { required, email } from '@validations';
import AvatarUpload from '@/components/avatarUpload/AvatarUpload.vue';
import PhoneInput from '@/components/phoneInput/PhoneInput.vue';
import { useStore } from '@/hooks/useStore';
import { useToast } from '@/hooks/useToast';
import { useI18n } from '@/hooks/useI18n';
import { useValidation } from '@/hooks/useValidation';
import { emailValidator, fieldRequiredValidator, minLengthValidator } from '@/utils/validation';

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BForm,
    BButton,
    BOverlay,
    BAlert,
    BLink,
    BSpinner,
    AvatarUpload,
    PhoneInput,
  },
  directives: {
    Ripple,
  },
  setup() {
    const { t } = useI18n();
    const store = useStore();
    const { successToast, dangerToast } = useToast();

    const accountForm = ref(null);

    const isProcessing = ref(false);

    const userData = computed(() => store.getters['user/data']);
    const isEmailConfirmed = computed(() => userData.value.is_email_confirmed);

    const model = {
      firstname: '',
      surname: '',
      phone: '',
      email: '',
      is_active: 1,
      is_serviceman: 1,
      ...['firstname', 'surname', 'phone', 'email', 'is_active', 'is_serviceman']
        .reduce((acc, val) => (
          userData.value[val] ? {
            ...acc,
            [val]: userData.value[val],
          } : acc
        ), {}),
    };
    const initialModel = ref(JSON.parse(JSON.stringify(model)));

    const minLength = 3;

    const [validate, errors] = useValidation([
      fieldRequiredValidator(model, 'firstname', t('errors.message.nameRequired')),
      minLengthValidator(model, 'firstname', minLength, t('errors.message.nameMinLength', {
        length: minLength,
      })),
      fieldRequiredValidator(model, 'surname', t('errors.message.surnameRequired')),
      minLengthValidator(model, 'surname', minLength, t('errors.message.surnameMinLength', {
        length: minLength,
      })),

      emailValidator(model, 'email', t('errors.message.emailInvalid')),
      fieldRequiredValidator(model, 'email', t('errors.message.emailRequired')),
    ]);

    const getUserData = async () => {
      await store.dispatch('user/getData');

      model.firstname = userData.value.firstname;
      model.surname = userData.value.surname;
      model.phone = userData.value.phone;
      model.email = userData.value.email;
      model.is_active = userData.value.is_active;
      model.is_serviceman = userData.value.is_serviceman;

      initialModel.value = JSON.parse(JSON.stringify(model));
    };
    getUserData();

    const phoneInput = ref(null);

    const onSubmit = async () => {
      const isFormValid = validate();
      const isPhoneValid = phoneInput.value.validate();
      if (!isFormValid || !isPhoneValid) return;

      isProcessing.value = true;
      const { result } = await store.dispatch('user/updateProfile', model);
      isProcessing.value = false;

      if (result) {
        successToast(
          t('settings.messageUpdated.title'),
          t('settings.messageUpdated.text'),
        );
        return;
      }

      dangerToast(
        t('settings.updateFailedMessage.title'),
        t('settings.updateFailedMessage.text'),
      );
    };

    const resendingVerification = ref(false);
    const resendVerification = async () => store.dispatch('user/resendVerification');
    const onResendVerification = async () => {
      resendingVerification.value = true;
      const { result } = await resendVerification();
      resendingVerification.value = false;

      if (result) {
        successToast(
          t('settings.profile.resendVerificationMessage.title'),
          t('settings.profile.resendVerificationMessage.text'),
        );
      }
    };

    const avatarUploadConfig = {
      updateHandler: (formData) => store.dispatch('user/updateUserAvatar', { formData }),
      deleteHandler: (formData) => store.dispatch('user/deleteUserAvatar', { formData }),
    };

    return {
      t,
      accountForm,

      userData,
      isEmailConfirmed,

      phoneInput,
      model,
      errors,
      isProcessing,

      required,
      email,

      onSubmit,

      resendingVerification,
      onResendVerification,

      avatarUploadConfig,
    };
  },
};
</script>

<style lang="scss">
.border-danger-inside div {
    border-color: #ea5455 !important;
}

.border-primary-inside:focus div {
    border-color: #7367f0 !important;
}

@media only screen and (max-width: 600px) {
    .media-aside {
        margin: 0 0 10px 33% !important;
    }
}

@import '@core/scss/vue/libs/vue-select.scss';
</style>

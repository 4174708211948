<template>
  <b-form-group class="phone-input">
    <label class="col-form-label">
      <slot name="label">
        {{ label }}
      </slot>
    </label>
    <b-input-group class="shadow-none">
      <b-input-group-prepend class="w-25">
        <v-select
          ref="codeSelect"
          v-model="model.phoneCode"
          :options="countryCodes"
          label="value"
          class="min-w-100 w-100"
          :disabled="disabled"
        >
          <template #option="{ title, name }">
            <b-img
              :src="require(`@/assets/images/flags/${name}.png`)"
              size="16"
            />
            <span>{{ title }}</span>
          </template>
        </v-select>
      </b-input-group-prepend>
      <div
        class="w-75"
      >
        <b-form-input
          ref="numberInput"
          :key="forceUpdate"
          v-model="model.phoneNumber"
          v-mask="model.phoneCode.mask"
          class="br-l-0 w-100 phone-input__number"
          type="text"
          :state="computedError ? false : null"
          :placeholder="model.phoneCode.mask"
          :disabled="disabled"
        />
        <small
          v-if="computedError"
          class="text-danger"
        >
          {{ computedError }}
        </small>
      </div>
    </b-input-group>
  </b-form-group>
</template>

<script>
import {
  BFormGroup,
  BFormInput,
  BInputGroup,
  BImg,
  BInputGroupPrepend,
} from 'bootstrap-vue';
import vSelect from 'vue-select';
import {
  computed,
  ref,
  toRefs,
  watch,
} from '@vue/composition-api';
import { phoneNumberLength } from '@core/utils/validations/validations';
import { clearPhoneNumber } from '@/utils/phone';
import { useStore } from '@/hooks/useStore';
import { useValidation } from '@/hooks/useValidation';
import { useI18n } from '@/hooks/useI18n';
import { fieldRequiredValidator, lengthValidator } from '@/utils/validation';

export default {
  name: 'PhoneInput',
  components: {
    BImg,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,

    vSelect,
  },
  props: {
    label: String,
    value: String,
    disabled: Boolean,
  },
  setup(props, { emit }) {
    const { t } = useI18n();
    const { value: modelValue } = toRefs(props);
    const store = useStore();

    const codeSelect = ref(null);
    const numberInput = ref(null);

    const countryCodes = computed(() => store.getters['auth/countryCodes'].map((code) => ({
      ...code,
      title: code.value,
      rules: `required|phoneNumberLength:${code.mask.match(/[#]/g).length}`,
    })));

    const model = ref({
      phoneCode: countryCodes.value[0],
      phoneNumber: '',
    });

    const [validate, errors] = useValidation(() => [
      fieldRequiredValidator(model.value, 'phoneNumber', t('errors.message.phoneNumberRequired')),
      lengthValidator(
        model.value,
        'phoneNumber',
        model.value.phoneCode.mask.length,
        t('errors.message.phoneNumberLength', {
          length: model.value.phoneCode.mask.match(/[#]/g).length,
        }),
      ),
    ]);

    const computedError = computed(() => errors.value.phoneNumber || null);

    const fillNumber = (val) => {
      if (!val) return;
      const clearedNumber = clearPhoneNumber(val);
      // const clearedNumber = clearPhoneNumber(val);
      const fixedNumber = clearedNumber.startsWith('+')
        ? clearedNumber
        : `+${clearedNumber}`;

      const currentCode = countryCodes.value.reduce(
        (acc, val) => ((!acc && fixedNumber.startsWith(val.value)) ? val : acc),
        null,
      );

      if (currentCode) model.value.phoneCode = currentCode;
      model.value.phoneNumber = fixedNumber.replace(model.value.phoneCode.value, '');
    };

    // fillNumber(modelValue.value);

    // Result
    const computedPhoneNumber = computed(() => {
      const code = model.value.phoneCode.value;
      const number = clearPhoneNumber(model.value.phoneNumber);

      return `${code}${number}`;
    });

    const forceUpdate = ref(null);
    watch(modelValue, (value) => {
      if (value !== computedPhoneNumber.value) {
        fillNumber(modelValue.value);
        forceUpdate.value = Date.now();
      }
    }, { immediate: true, deep: true});

    watch(computedPhoneNumber, (val) => {
      emit('input', val);
    });

    watch(model.value, () => {
      const currentCountry = model.value.phoneCode;
      if (currentCountry.name === 'ae') {
        const firstDigit = model.value.phoneNumber[0];
        // eslint-disable-next-line radix
         model.value.phoneCode.mask = Number.parseInt(firstDigit) === 0 ? '###-###-####' : '#-###-####';
      }
    });

    return {
      codeSelect,

      numberInput,
      countryCodes,

      model,

      validate,
      computedError,

      phoneNumberLength,
      forceUpdate,
    };
  },
};
</script>

<style lang="scss">
$phoneCodeSelectWidth: 65px;

.phone-input {
  .col-form-label {
    padding-top: 0;
  }
  .w-75 {
    width: calc(100% - #{$phoneCodeSelectWidth}) !important;
  }
  .w-25 {
    width: $phoneCodeSelectWidth !important;
  }

  .vs__dropdown-toggle {
    border-radius: 5px 0 0 5px !important;
    height: 38px;
  }

  .vs__actions {
    float: right !important;
    margin-left: auto !important;
  }

  .vs__search {
    width: 1px !important;
    padding: 0 !important;
  }

  .vs__search:disabled {
        display: none;
  }

    .vs__clear {
    display: none;
  }

  span.vs__selected {
    text-align: center !important;
  }

  &__number {
    border-radius: 0 0.357rem 0.357rem 0 !important;
  }

  .border-grey-1 {
    border: 1px solid #d8d6de !important;
    border-radius: 0 0.357rem 0.357rem 0 !important;
  }
}

@import '@core/scss/vue/libs/vue-select.scss';
</style>
